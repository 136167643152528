import React from "react"; 
import { Link } from "react-router-dom";

import sr from '../assets/square-repair.webp'
import as from '../assets/armstrong-supplies.webp'
import ts from '../assets/thesoapery.webp'

const Results = () => {
    return (
        <>
        <div className="sixth">
            <div className="six-1">
                <div className="a6">
                    <h4 className="org-name">Square Repair</h4>
                    <p className="org-desc">The client is a small phone repair with two<br/> 
                    employees and a low market budget looking<br/> 
                    to grow nationally.</p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Organic Traffic Growth:</h5>
                    <h4 className="org-perc">+502.87%</h4>
                    <h4 className="cs-link"><Link to="casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={sr} alt=""/>
                </div>
            </div>

            <div className="six-1">
                <div className="a6">
                    <h4 className="org-name">Armstrong Supplies</h4>
                    <p className="org-desc">This is an online-only store for buying building 
                    <br/> 
                    materials, supplies, and equipment in the UK.</p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Organic Traffic Growth:</h5>
                    <h4 className="org-perc">+495.00%</h4>
                    <h4 className="cs-link"><Link to="casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={as} alt=""/>
                </div>
            </div>

            <div className="six-1">
                <div className="a6">
                    <h4 className="org-name">The Soapery</h4>
                    <p className="org-desc">The client is a natural ingredients store for <br/> 
                    making soap at home. Their no-nonsense <br/>  approach is loved by many with over 100,000
                    <br/>  online sales.
                    </p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Organic Traffic Growth:</h5>
                    <h4 className="org-perc">142.00%</h4>
                    <h4 className="cs-link"><Link to="casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={ts} alt=""/>
                </div>
            </div>

            <h5 className="cases"><Link to="casestudy" className="white-link">Read all case studies</Link></h5>
        </div>
    </>
    )
}

export default Results;