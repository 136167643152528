import React from "react";

import step1 from './assets/step-1.svg'

const StepOne = () => {
    return(
        <>
        <div className="S1">
            <img src={step1} alt=""/>
            <h5>step one</h5>
            <h4>Strategy Audit</h4>
            <h6>A detailed review of every page of your website from a technical and content perspective.
                <br/><br/>
                The goal here is to set up your site for search engines to easily crawl and access your many pages.
            </h6>
        </div>
        </>
    )
}

export default StepOne;