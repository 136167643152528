import React, { useEffect, useState} from "react";
import SinglePost from './blogComponents/singlePost';
import he from 'he';
import './blog.css';

import ne from '../navbar/ness.png'


const Blog = () => {

const wordCount = (html) => html.replace(/<[^>]*>/g, ' ').match(/\S+/g)?.length || 0;

// const [data, setData] = useState([]);
// // const [imageUrl, setImageUrl] =([]);
// useEffect(() => {
//     getData();
// }, [])

// useEffect(() => {
//     getImageData(0);
// }, [])

// const getData = async () => {
//     const response = await fetch("https://geeksquadagency.com/blog/ness-marketing/");
//     const apiData = await response.json()
//     setData(apiData);
//     console.log(data)
// }

// const postId = data.map(item => item.featured_media )

// const getImageData = async (index) => {
//     if (index >= postId.length) {
//         console.log(imageUrl)
//         return
//     }
//     const imageresponse = await fetch(`https://geeksquadagency.com/blog/wp-json/wp/v2/media/${postId[index]}`);
//     const imageData = await imageresponse.json()
//     imageUrl.push(imageData.source_url);
//     console.log(imageUrl)
//     getImageData(index + 1)
// }

// for (let i = 0; i < imageUrl.length; i++) {
//     const postData = data.map(item =>({
//         ...item,
//         media: imageUrl[i],
//     }))
//     console.log(postData)
// }

const [posts, setPosts] = useState([]);
const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  const fetchPostsWithImages = async () => {
    try {
      const postsResponse = await fetch('https://geeksquadagency.com/blog/wp-json/wp/v2/posts', {
  headers: {
    'mode':'no-cors'
  }
});
      const postsData = await postsResponse.json();
      

      const postImagePromises = postsData.map(async post => {
        // if (post.featured_media && post.categories[0]) {
            const imageResponse = await fetch(`https://geeksquadagency.com/blog/wp-json/wp/v2/media/${post.featured_media}`);
            const imageData = await imageResponse.json();

            const categoryId = post.categories[0]; 
            const categoryResponse = await fetch(`https://geeksquadagency.com/blog/wp-json/wp/v2/categories/${categoryId}`);
            const categoryData = await categoryResponse.json();

            const fullPostReponse = await fetch(`https://geeksquadagency.com/blog/wp-json/wp/v2/posts/${post.id}`);
            const fullPost = await fullPostReponse.json();
            return { ...post, image: imageData, category: categoryData, fullPost: fullPost };
        // }
        // return post;
      });

      const postsWithImages = await Promise.all(postImagePromises);
      setPosts(postsWithImages);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchPostsWithImages();
}, []);




console.log(posts)

    return (
    <>
    <SinglePost posts={posts}/>
        <div className="blog">
            <div className="b-header">
                <h3>
                Join 1,641 Shopify Stores On Our Growth Marketing Newsletter
                </h3>
                <h5>
                Get the latest strategies, playbooks, and tactics for growing your Shopify store
                </h5>
            </div>

            <div className="posts">
                {posts?.map((values) => {
                    return (
                        // <>
                <div key={values.featured_media} >

                <a href={values.link}><div className="post">
                <div className="pp">
                    <img src={values.image.source_url} alt=''/>
                    {/* <h3>{values?._embedded.author[0]}</h3> */}
                </div>

                <div className="pa">
                    <div className="pa-top">
                        <div className="cart">{values.category.name}</div>
                        <div className="divide">|</div>
                        <div className="readtime">{Math.round(wordCount(values.fullPost?.content.rendered)/50)} min read</div>
                    </div>

                    
                    <div className="art-title">{values.title.rendered}</div>
                    <div className="art-summary" dangerouslySetInnerHTML={{__html: values.content?.rendered}}/>
                    

                    <div className="more"><a href={values.link}>Read more</a></div>
                </div>


                </div></a>
               </div>


            //    </>
                    )


                })}
                

                {/* <div className="post">
                    <div className="pp">
                        <img src={ne} alt="" />
                    </div>

                    <div className="pa">
                        <div className="pa-top">
                            <div className="cart">Shopify Themes</div>
                            <div className="divide">|</div>
                            <div className="readtime">12 min read</div>
                        </div>

                        
                        <div className="art-title">Shopify Footer Design: Everything You Need to Know</div>
                        <div className="art-summary">
                            When designing an eCommerce website, the footer is often an overlooked aspect. Despite its seemingly minor placement, the footer plays a crucial role in creating an impression and ultimately converting visitors into paying customers. It’s the yin to the header’s yang. After reviewing the footer designs and conversion tactics
                        </div>
                        

                        <div className="more">Read more</div>
                    </div>
{
  headers: {
    'mode':'cors'
  }
}

                </div> */}


            </div>
        </div>
    </>
    )
}

export default Blog;