import React from 'react'
import { NavLink } from 'react-router-dom'
import logie from './ness.png'
// import phone from './phone.svg'
import './Nav.css'
import { useState } from 'react'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    {/* <nav className="Navbar"> */}
      {/* <div className='topnav'>
        <li><NavLink to="/">For Advertisers</NavLink></li>
        <li><NavLink to="/venues">For Venues</NavLink></li>
      </div> */}
      <div className='nav-b'>
      <div className="Navbar">
        <span className="logo">
          <NavLink to="/"><img src={logie} alt=''/></NavLink>
        </span>
        
        <div className={`nav-items ${isOpen && "open"}`}>
          
            <li>
              <NavLink to="about" 
              onClick={() => setIsOpen(!isOpen)}
              >About</NavLink>
            </li>
            <li>
              <NavLink to="process" onClick={() => setIsOpen(!isOpen)}>Process</NavLink>
            </li>
            <li className='split'>
              <NavLink to="results" onClick={() => setIsOpen(!isOpen)}>Results</NavLink>
            </li>
            <li className='phone-sec'>
              <a href="https://geeksquadagency.com/blog/" onClick={() => setIsOpen(!isOpen)}>
               Blog</a>
            </li>
            <li className="b-a-demo">
              <NavLink to="/contact"  onClick={() => setIsOpen(!isOpen)}>Let's talk</NavLink>
            </li>
            {/* <li className='log-in'>
              <NavLink to="/login" onClick={() => setIsOpen(!isOpen)}>Login</NavLink>
            </li> */}
        </div>
        <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
      </div>
      </div>
    {/* </nav> */}
    </>
  )
}

export default Navbar