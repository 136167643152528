import React from "react";

import step3 from './assets/step-3.svg'

const StepThree = () => {
    return(
        <>
        <div className="S1">
            <img src={step3} alt=""/>
            <h5>step three</h5>
            <h4>Content Marketing</h4>
            <h6>Not everyone is searching for your products straight away, before that they may search for 
                information, questions, reviews, comparisons, etc.
                <br/><br/>
                Our content marketing approach is aimed at targeting the "middle" and "top of funnel" to get more potential
                customers to your store while driving traffic to profitable product pages
            </h6>
        </div>
        </>
    )
}

export default StepThree;