import React from "react";

import step2 from './assets/step-2.svg'

const StepTwo = () => {
    return(
        <>
        <div className="S1">
            <img src={step2} alt=""/>
            <h5>step two</h5>
            <h4>Shopping Page Optimisation</h4>
            <h6>Our campaign prioritises this by improving each 
                of your important pages with keyword research, title and meta improvements, content optimisation, etc.
                <br/><br/>
                Your shopping pages (i.e. homepage, collections, and products) are where the majority of revenue 
                is generated - even from a "funnel entrance" perspective.
            </h6>
        </div>
        </>
    )
}

export default StepTwo;