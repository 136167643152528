import React from "react";

import arrow from './assets/arrow.svg';
import cols from './assets/cols.svg';
import env from './assets/envelope.svg';
import link from './assets/link.svg'; 

import midfunnnel from '../assets/MiddleFunnel.png';
import topfunnel from '../assets/TopOfFunnel.png'

const Consume = ()=>{

    const handleScrollOne = () =>{
        const elm1 = document.getElementById('Sec-a');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollTwo = () =>{
        const elm1 = document.getElementById('Sec-b');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollThree = () =>{
        const elm1 = document.getElementById('Sec-c');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollFour = () =>{
        const elm1 = document.getElementById('Sec-d');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    return (
    <>
        
        <div className="strat2">
            <div className="strat-nest-a">
                <ul>
                    <li>
                        <div className="nest-a" onClick={handleScrollOne}>
                            <div className="nest-a-icon">
                                <img src={arrow} alt="" />
                            </div>
                            <div className="nest-a-text">
                                <h5>Optimise landing pages</h5>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="nest-a" onClick={handleScrollTwo}>
                            <div className="nest-a-icon">
                                <img src={cols} alt="" />
                            </div>
                            <div className="nest-a-text">
                                <h5>Middle of funnel content</h5>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="nest-a" onClick={handleScrollThree}>
                            <div className="nest-a-icon">
                                <img src={env} alt="" />
                            </div>
                            <div className="nest-a-text">
                                <h5>Top of funnel content</h5>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="nest-a" onClick={handleScrollFour}>
                            <div className="nest-a-icon">
                                <img src={link} alt="" />
                            </div>
                            <div className="nest-a-text">
                                <h5>Link building</h5>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="strat-nest-b">
                <h5 id="Sec-a">
                    1. Reach more shoppers by expanding your <span className="c1-accent">landing pages</span>
                </h5>

                <h6>
                
                If you only have a handful of products, it's virtually impossible to rank a category page featuring your 3 products against a retailer with 300.
                <br/><br/>
                But you do have one option, landing pages.
                <br/><br/>
                Let's say you sell a trimmer, you may well want to rank for searches of "trimmer", but how can we angle this same product for multiple keywords and audiences?
                <br/><br/>
                You create a landing page for:

                <div className="divs">
                    <div>Electric trimmer</div>
                    <div>Beard trimmer</div>
                    <div>Moustache trimmer</div>
                    <div>Women's trimmer</div>
                    <div>Bikini trimmer</div>
                    <div>Men's trimmer</div>
                    <div>Balls trimmer</div>
                </div>
                Now this single product has 7 landing pages with different keywords to target, allowing you to significantly increase the traffic potential.
                <br/><br/>
                We'll help you with this by doing the keyword research, planning out what landing pages to create and creating a plan for how to optimise these.
                </h6>

                {/* <img src={} alt=""/> */}

                <h5 id="Sec-b">
                    2. Create more <span className="c2-accent">middle of funnel content</span>
                </h5>
                <h6>                  
                                
                Beyond shopping pages, there's soon to be shopping queries.
                <br/><br/>
                Like "best espresso machine", "[product] reviews", or "[product] vs [product]". These indicate someone is researching to likely make a purchase.
                <br/><br/>
                Or even something like "valentines gift ideas", if you're a store selling flowers and gifts.
                <br/><br/>
                As a DTC brand, you'll need to be fair when it comes to competitor product reviews. But for things like "valentines gift ideas", it's as simple as recommending and linking to your products.
                <br/><br/>
                We help with this by turning our original content ideas into detailed content briefs that will rank well, including:
                <br/><br/>
                <ul>
                    <li>Post title / H1</li>
                    <li>Recommended internal links</li>
                    <li>Subheading structure for keyword relevance</li>
                    <li>Suggested phrases for topical relevance</li>
                    <li>SERP feature notes such as how to structure an answer to rank in the featured snippet</li>
                    <li>Recommended word count based depth of competitors content</li>
                    <li>FAQs for People Also Ask section, schema, and overall relevance</li>
                    <li>Suggested scheduling order and date for searching engine rankings</li>
                </ul>
                <br/><br/>
                You can then hand this over to your writers and get amazing ready-to-rank content to start driving traffic.
                </h6>

                <img src={midfunnnel} alt=""/>
                
                <h5 id="Sec-c">
                    3. Drive more traffic with <span className="c3-accent">top of funnel content</span>
                </h5>
                <h6>
                
                The final stage of content is top of funnel, which is also important for topical coverage.
                <br/><br/>
                The goal is to drive traffic and position your website as a content authority.
                <br/><br/>
                Let's take one of our clients that sells brick slips, beyond the bottom of funnel searches such as:
                <br/><br/>
                <ul>
                    <li>brick slips (25k monthly searches)</li>
                    <li>brick slips uk (2.3k monthly searches)</li>
                    <li>brick slips near me (1.8k monthly searches)</li>
                </ul>
                <br/><br/>
                Let's take one of our clients that sells brick slips, beyond the bottom of funnel searches such as:
                <br/><br/>
                <ul>
                    <li>what are brick slips (400 monthly searches)</li>
                    <li>how to fit brick slips (250 monthly searches)</li>
                    <li>how to cut brick slips (80 monthly searches)</li>
                </ul>
                <br/><br/>
                By covering these topics with content, we're both driving additional relevant traffic to the website, as well as establishing the site as an authority on this topic by our coverage and depth.


                </h6>

                <img src={topfunnel} alt=""/>
                
                <h5 id="Sec-d">
                    4. Rank keywords through <span className="c4-accent">link building</span>
                </h5>
                <h6>
                
                    Based on your DTS audit, you may need more backlinks to rank for certain keywords.
                    <br/><br/>
                    Using our strategy plan of which pages to point links to, how many links to build, what anchor text to use, etc. We'll do the outreach and find link opportunities for you.
                    <br/><br/>
                    This involves:
                    <br/><br/>
                    <ul>
                        <li>Creating a list of relevant websites/articles that would be beneficial to get a link from</li>
                        <li>Vet the quality of the websites to check they're of decent quality and how impactful a link would be</li>
                        <li>Reach out to these websites by email to see if they're open to this</li>
                        <li>Discuss and negotiate link placement details</li>
                        <li>Write the content for the link placement (if needed)</li>
                        <li>Monitor the link status and update you once live</li>
                    </ul>
                </h6>

                {/* <img src={} alt=""/> */}
            </div>
        </div>
    </>
    )
}

export default Consume;

