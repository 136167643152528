import React, {useEffect} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/bundle";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Navigation } from "swiper";

import raa from './assets/reebelo-au-ahrefs.webp';
import rgsc from './assets/reebelo-au-gsc.webp';
import rga from './assets/reebelo-au-analytics.webp';

const ROne = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);
    
    return (
        <>
            <div className="rescont">
                <div className="rs-cont1">
                    <h4>Ahrefs</h4>
                    <h5>Ahrefs shows the scale of keyword 
                        growth in such a short period of 
                        time, growing by over 24,000 keywords 
                        in 9 months</h5>
                </div>
                <div className="rs-cont2">
                    <img src={raa} alt=''/>
                </div>
            </div>
        </>
    )
}

const RTwo = () => {
    return (
        <>
            <div className="rescont">
                <div className="rs-cont1">
                    <h4>Google Search Console</h4>
                    <h5>This isn’t all brand growth too, GSC with brand name filtering applied still shows significant growth during the past 12 months</h5>
                </div>
                <div className="rs-cont2">
                    <img src={rgsc} alt=''/>
                </div>
            </div>
        </>
    )
}

const RThree = () => {
    return (
        <>
            <div className="rescont">
                <div className="rs-cont1">
                    <h4>Google Analytics</h4>
                    <h5>GA shows the scale of this growth when comparing to the previous years numbers, organic revenue is up over 8x</h5>
                </div>
                <div className="rs-cont2">
                    <img src={rga} alt=''/>
                </div>
            </div>
        </>
    )
}

const ResSlide = () => {
    return (
        <>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide><ROne/></SwiperSlide>
            <SwiperSlide><RTwo/></SwiperSlide>
            <SwiperSlide><RThree/></SwiperSlide>
          </Swiper>
        </>
    );
};

export default ResSlide;