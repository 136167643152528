import React, { useEffect } from 'react';
import {BrowserRouter as Router, Link, Routes, Route, Navigate, redirect} from 'react-router-dom'
import logo from './logo.svg';
import './App.css';

import HomePage from './components/homepage/HomePage';
import StepOne from './components/homepage/homepageComponents/stepOne';
import StepTwo from './components/homepage/homepageComponents/stepTwo';
import StepThree from './components/homepage/homepageComponents/stepThree';
import StepFour from './components/homepage/homepageComponents/stepFour';
import About from './components/about/About';
import Process from './components/process/Process';
import Retailer from './components/process/processComponents/Retailer';
import Consume from './components/process/processComponents/DirectToConsumer';
import Res from './components/results/Results';
import CaseOne from './components/cases/CaseOne';
import NavBar from './components/navbar/Navbar';
import Contact from "./components/contact/About";
import Footer from './components/footer/Footer';
import { MissingRoute } from './components/missingRoute/MissingRoute';
import BookCall from './components/bookAcall/bookCall';
import Blog from './components/blog/blog';
import BlogPost from './components/blog/test';
// import BlogDetail from './components/blog/blogComponents/blogComponent';

function App() {

  useEffect(() => {
    document.title = 'Geek Squad Digital';
  }, []);
  return (
    <>
   
    <Router>
      <NavBar/>
      <Routes>
        <Route path='/' element={<HomePage/>} >
          <Route path='step1' element={<StepOne/>}/>
          <Route path='step2' element={<StepTwo/>}/>
          <Route path='step3' element={<StepThree/>}/>
          <Route path='step4' element={<StepFour/>}/>
        </Route>
        <Route path="/process" element={<Process/>} >
          <Route path="retailer" element={<Retailer/>}/>
          <Route path="consumer" element={<Consume/>}/>
        </Route>
        <Route path="/blog." element={<Blog/>} />
        <Route path='/about' element={<About/>}/>
        <Route path='/results' element={<Res/>}/>
        <Route path='/casestudy' element={<CaseOne/>}/>
        <Route path='/contact' element={<BookCall/>}/>
        <Route path="*" element={<MissingRoute/>} />
      </Routes>
      <Footer/>   
    </Router>
    </>
  );
}

export default App;
