import React, {useEffect} from "react";
// import { Link, Outlet, Router } from "react-router-dom";
import {BrowserRouter as Router, Link, Routes, Outlet, Route, Navigate, redirect} from 'react-router-dom'

import './home.css'

import pic1 from './assets/pic1.png'
import rank from './assets/googlerank.png'
import cart from './assets/cart.webp'
import overlaypic from './assets/overlay.png'

import armstrong from './assets/icons/armstrong-supplies.png'
import chris from './assets/icons/chris-coffee.png'
import custom from './assets/icons/custom-gold-grillz.png'
import flawless from './assets/icons/flawless-vape.png'
import iss from './assets/icons/iss-automotive.png'
import pc from './assets/icons/pc-international.png'
import reebelo from './assets/icons/reebelo.png'
import square from './assets/icons/square-repair.png'
import tackle from './assets/icons/tackle-room.png'
import soapery from './assets/icons/thesoapery.png'

import greenblob from './assets/blob-green.svg'
import redblob from './assets/blob-red.svg'
import goldblob from './assets/blob-gold.svg'

import Results from "./homepageComponents/results";
import StepFour from "./homepageComponents/stepFour";
import StepThree from "./homepageComponents/stepThree";
import StepTwo from "./homepageComponents/stepTwo";
import StepOne from "./homepageComponents/stepOne";

const HomePage = () =>{
    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    // const loader = async () => {
    //     redirect("/step1");
    // };
    // loader()
    return (
        
        <>
    
        {/* <div className="body"> */}
            <div className="first">
                <img className="greenblob" src={greenblob} alt=""/>
                <img className="redblob" src={redblob} alt=""/>
                <img className="goldblob" src={goldblob} alt=""/>
                <h3>Done-For-You<br/>Revenue Growth for<br/>Businesses</h3>
                <h6>Our clients have seen a 10.55x ROI (... and counting!)</h6>
                <div className="menu">
                    <div className="menu-a"><Link to="contact">Book a call</Link></div>
                    <div className="menu-b">or</div>
                    <div className="menu-c"><Link to="about" className="white-link">learn more</Link></div>
                </div>
                
            </div>
            
            <div className="image-div">
                    <img className="image-fg" src={pic1} alt=""/>
                    <img className="overlay-pic" src={overlaypic} alt=""/>
            </div>

            <div className="second">
                <div className="a2">
                    <h2>Our client ranks #1 nationwide for "grillz".<span className="green"> What are your customers searching for?</span> </h2>
                    <br/><br/>
                    <h5>SEO is simple. People search for your products, product categories, or information
                    about either - then your website is what they see.
                    <br/><br/>
                    No complex targeting, forever increasing CPC’s, or losing all your business data to Ama-gone.
                    <br/><br/>
                    We’ve done this for many stores already, our clients have seen an average 143.28% growth in 
                    revenue, and we have a simple approach to increasing revenue every time</h5>
                </div>
                <div className="b2">
                    <img src={rank} alt=""/>
                </div>
            </div>

            <div className="slider">
                <h3>Trusted by fast growing eCommerce brands</h3>
                <div className="items">
                    <div className="item">
                        <img src={armstrong} alt=""/>
                    </div>
                    <div className="item">
                        <img src={chris} alt=""/>
                    </div>
                    <div className="item">
                        <img src={custom} alt=""/>
                    </div>
                    <div className="item">
                        <img src={flawless} alt=""/>
                    </div>
                    <div className="item">
                        <img src={iss} alt=""/>
                    </div>
                    <div className="item">
                        <img src={pc} alt=""/>
                    </div>
                    <div className="item">
                        <img src={reebelo} alt=""/>
                    </div>
                    <div className="item">
                        <img src={square} alt=""/>
                    </div>
                    <div className="item">
                        <img src={tackle} alt=""/>
                    </div>
                    <div className="item">
                        <img src={soapery} alt=""/>
                    </div>
                    
                </div>
            </div>

            <div className="third">
                <h3>How we grow your <span className="crossed">traffic</span> revenue</h3>
                <p>Take a look at our simple 4-step process for growing organic search traffic for<br/>  Shopify stores fast.</p>

                <div className="nested">
                    <div className="selector">
                        <ul>
                        <li><Link to="step1" className="white-link">1. Strategy Audit</Link></li>
                        <li><Link to="step2" className="white-link">2. Shopping Page Optimisation</Link></li>
                        <li><Link to="step3" className="white-link">3. Content Marketing</Link></li>
                        <li><Link to="step4" className="white-link">4. Link Building</Link></li>
                        </ul>
                    </div>
                    <div className="selected">
                    <Routes>
                        <Route path='/*' element={<StepOne/>}/>
                        <Route path='/step2' element={<StepTwo/>}/>
                        <Route path='/step3' element={<StepThree/>}/>
                        <Route path='/step4' element={<StepFour/>}/>
                    </Routes>
                        {/* <Outlet/> */}
                    </div>
                </div>
            </div>

            <div className="fourth">
                <h3>What's the revenue potential of your store?</h3> <span className="arrow"></span> <Link to="contact"><h4>Book a call</h4></Link>
            </div>

            <div className="fifth">
                <h6>need proof?</h6>
                <h3>Here's some amazing results</h3>
                <h5>At the end of the day, it comes down to results, and we deliver.<br/>
                Our worst result was +35.4% traffic in 7 months. Here’s some of<br/>
                our highlights:</h5>
            </div>

            <Results/>

            {/* <div className="seven">
                <div className="a7">
                    <h3>Our clients have seen an average<br/> 10.55x ROI.
                        What's the potential for<br/>  your store?
                    </h3>

                    <p>Book a free analysis and we'll crunch your data,
                         analyse competitors, and show you the revenue you should be seeing
                    </p>

                    <h5>Book a Free Analysis</h5>
                </div>
                <div className="b7">
                    <img src={cart} alt=""/>
                </div>
            </div> */}
        {/* </div> */}
        </>
    )
}

export default HomePage;