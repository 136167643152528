import React, {useEffect} from "react";
import './res.css';
import { Link } from "react-router-dom";

import sq from './assets/square-repair-testimonial.jpg';
import toe from './assets/timeoffediting-testimonial.jpg';
import tg from './assets/the-goodest-testimonial.jpg';
import tr from './assets/tackleroom.jpeg';

import sr from '../homepage/assets/square-repair.webp';
import as from '../homepage/assets/armstrong-supplies.webp';
import ts from '../homepage/assets/thesoapery.webp';
import tack from './assets/tackle-room.webp';
import pci from './assets/pc-intl.webp';
import blin from './assets/blindstyle.webp';
import reeb from './assets/reebelo.webp';
import iss from './assets/iss-automotive.webp'

// import Results from "../homepage/homepageComponents/results";

const Res = ()=> {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Geek Squad Digital | Process';
      }, []);

    return (
        <>
        <div className="body">
            <div className="first">
                <h3>Our average client has<br/> 173.89% more organic <br/> traffic than last year</h3>
                <div className="menu">
                    <div className="menu-a"><Link to="/contact">Book a call</Link></div>
                    <div className="menu-b">or</div>
                    <div className="menu-c"><Link to="/about" className="white-link">learn more</Link></div>
                </div>
            </div>

            <div className="res-2">
                <h5>check out the numbers</h5>
                <h3 className="header-2">Here's some of our recent results</h3>
            </div>

            <div className="sixth">
            <div className="six-1">
                <div className="a6">
                    <h4 className="org-name">Square Repair</h4>
                    <p className="org-desc">The client is a small phone repair with two<br/> 
                    employees and a low market budget looking<br/> 
                    to grow nationally.</p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Organic Traffic Growth:</h5>
                    <h4 className="org-perc">+502.87%</h4>
                    <h4 className="cs-link"><Link to="/casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={sr} alt=""/>
                </div>
            </div>

            <div className="six-1">
                <div className="a6">
                    <h4 className="org-name">Armstrong Supplies</h4>
                    <p className="org-desc">This is an online-only store for buying building 
                    <br/> 
                    materials, supplies, and equipment in the UK.</p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Organic Traffic Growth:</h5>
                    <h4 className="org-perc">+495.00%</h4>
                    <h4 className="cs-link"><Link to="/casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={as} alt=""/>
                </div>
            </div>

            <div className="six-1 soa">
                <div className="a6">
                    <h4 className="org-name">The Soapery</h4>
                    <p className="org-desc">The client is a natural ingredients store for <br/> 
                    making soap at home. Their no-nonsense <br/>  approach is loved by many with over 100,000
                    <br/>  online sales.
                    </p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Organic Traffic Growth:</h5>
                    <h4 className="org-perc">142.00%</h4>
                    <h4 className="cs-link"><Link to="/casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={ts} alt=""/>
                </div>
            </div>

            <div className="six-1 ttr">
                <div className="a6">
                    <h4 className="org-name">The Tackle Room</h4>
                    <p className="org-desc">
This is a niche Shopify store in the US selling a variety of saltwater fishing supplies and equipment.</p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Organic Traffic Growth:</h5>
                    <h4 className="org-perc">+170.49%</h4>
                    <h4 className="cs-link"><Link to="/casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={tack} alt=""/>
                </div>
            </div>

            <div className="six-1 pci">
                <div className="a6">
                    <h4 className="org-name">PC International</h4>
                    <p className="org-desc">
This is a popular brand and online store in South Africa for purchasing computers and laptops in-store and online</p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Organic Traffic Growth:</h5>
                    <h4 className="org-perc">+466.93%</h4>
                    <h4 className="cs-link"><Link to="/casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={pci} alt=""/>
                </div>
            </div>

            <div className="six-1 bls">
                <div className="a6">
                    <h4 className="org-name">Blindstyle</h4>
                    <p className="org-desc">
This client was an offline local business looking to re-establish itself as an online store for made-to-measure window blinds.</p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Annual Traffic Value:</h5>
                    <h4 className="org-perc">+$74,400</h4>
                    <h4 className="cs-link"><Link to="/casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={blin} alt=""/>
                </div>
            </div>

            <div className="six-1 reeb">
                <div className="a6">
                    <h4 className="org-name">Reebelo</h4>
                    <p className="org-desc">
Reebelo is a fast growing marketplace for refurbished and pre-loved devices in multiple countries built on Shopify with a custom headless storefront..</p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Annual Traffic Value:</h5>
                    <h4 className="org-perc">+$323,652</h4>
                    <h4 className="cs-link"><Link to="/casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={reeb} alt=""/>
                </div>
            </div>

            <div className="six-1">
                <div className="a6">
                    <h4 className="org-name">ISS Automotive</h4>
                    <p className="org-desc">
ISS Automotive is an online D2C brand for instrument clusters and replacement electronic car parts.</p>
                    <div className="border-grey"></div>
                    <h5 className="org-growth">Monthly Users Growth:</h5>
                    <h4 className="org-perc">+55,877</h4>
                    <h4 className="cs-link"><Link to="/casestudy">Read case study</Link></h4>
                </div>
                <div className="b6">
                    <img src={iss} alt=""/>
                </div>
            </div>

            {/* <h5 className="cases">Read all case studies</h5> */}
        </div>

            <div className="res-4">
                <h3 className="header-2">And here's what they are saying...</h3>
                <div className="clients">
                    <div className="client">
                        <img src={sq} alt=""/>
                        <h5>Stephen Levy</h5>
                        <h5>Director, SquareRepair</h5>
                        <h5>“Thanks again for all of your 
                            stellar work, I’m glad I stuck 
                            at it. You have practically 
                            changed my life."</h5>
                    </div>

                    <div className="client">
                        <img src={toe} alt=""/>
                        <h5>Josh Austin</h5>
                        <h5>CEO, TimeOffEditing</h5>
                        <h5>"Geek Squad Digital helped us add an extra 
                            $156,000 per year of recurring 
                            clients within the first 6 months."</h5>
                    </div>

                    <div className="client">
                        <img src={tg} alt=""/>
                        <h5>Michael Williams</h5>
                        <h5>Co-Founder, The Goodest</h5>
                        <h5>"Really appreciate your help. 
                            It was enlightening to see what 
                            someone completely removed from 
                            our business was taking away."</h5>
                    </div>
                </div>
            </div>

            <div className="res-5">
                <div className="img">
                    <img src={tr} alt='' />
                </div>
                <div className="tr-txt">
                    <h5>
                    "We found ourselves with a solid site and traffic, but relying
                     heavily on paid traffic sources to generate revenue. Geek Squad Digital stepped 
                     up and helped us gain the organic presence that we so badly needed.
                     <br/><br/>
                    We chose Geek Squad Digital because Troy was relatable and easy to talk with. We 
                    felt he was authentic and didn’t seek to mislead us or make a quick buck.
                    <br/><br/>
                    The best part about working with Geek Squad Digital were the accountability and 
                    solid communication, and our organic traffic has improved significantly.
                    <br/><br/>
                    We are quite pleased with you guys. Thank you very much."
                    <br/><br/>
                    </h5>
                    <div className="border-grey"></div>
                    <h3>Bryan Byrd</h3>
                    <h5>Co-Founder, The Tackle Room</h5>
                </div>
            </div>
        </div>
        </>
    )
}

export default Res;