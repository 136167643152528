import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import './about.css';

import Results from "../homepage/homepageComponents/results";

import mg from './assets/megaphone.png';
import mc from './assets/money-chart.png';
import rc from './assets/rocket.png';
import se from './assets/shopify-experts.png';
import tr from './assets/trophy.png';
import ah from './assets/ahrefs.png';
import pop from './assets/page-optimizer-pro.png';
import sej from './assets/search-engine-journal.png';
import th from './assets/the-hoth.png';
import qt from './assets/quote.svg';
import yt from './assets/youtube.png'

const About = () =>{

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Geek Squad Digital | About';
      }, []);

    return (
        <>
            <div className="first">
                <div className="ab-a">
                    <h4>Geek Squad Digital - Grow Organic <span className="b-gb">Search Revenue</span> For Businesses</h4>
                    <p>Not impressions, nor clicks, not even traffic. Organic search<br/>
                        marketing that creates hockey stick revenue growth for e-commerce<br/> stores.
                    </p>
                    <div className="menu-a"><Link to="/contact">Book a call</Link></div>
                </div>   
                <div className="ab-b">
                </div>  
            </div>
            <div className="about-2">
                <h3>Why choose Geek Squad Digital?</h3>

                <div className="second">
                    <div className="a2">
                        <h2>
                        You need a team that understand Shopify better than you
                        </h2>  
                        <h5>
                        You shouldn't have to teach someone how to do what you want, wait for them to research it, or worse - research yourself.
                        <br/><br/>
                        We know Shopify inside out. Even as far as developing our own custom tools and running our own online shop.
                        <br/><br/>
                        Why start from scratch?
                        </h5>             
                    </div>
                    <div className="b2">
                        <img src={se} alt=""/>
                    </div>
                </div>

                <div className="second  s1">
                <div className="b2">
                        <img src={rc} alt=""/>
                    </div>
                    <div className="a2">
                        <h2>You need a moat around your business</h2>  
                        <h5>
                            Platforms are great for quick sales, but you're forever at the mercy of a soulless company.
                            <br/><br/>
                            And ads are amazing, until they aren't. Ad costs are endlessly rising, your traffic is held hostage to payment, and it's a full time job keeping it performing.
                            <br/><br/>
                            Organic search not only provides traffic and revenue, it builds a moat around your business.
                            <br/><br/>
                            Ads and platforms are one competitor away from being unprofitable. Great SEO will take years to catch.
                        </h5>             
                    </div> 
                </div>

                <div className="second">
                <div className="a2">
                        <h2>You need revenue growth not useless content traffic</h2>  
                        <h5>
                            You've probably worked with SEO agencies before and all they did was seemingly fix "issues" and publish crappy content.
                            <br/><br/>
                            The end result? High SEO scores, maybe even higher traffic. And all of... zero revenue growth.
                            <br/><br/>
                            Our playbook is designed to grow revenue above all else. And with an average 10.55x ROI, we'd say it's working
                        </h5>             
                    </div>
                    <div className="b2">
                        <img src={mg} alt=""/>
                    </div>
                </div>

                <div className="second s1">
                    <div className="b2">
                        <img src={tr} alt=""/>
                    </div>
                    <div className="a2">
                        <h2> You don't want to wait a decade to see revenue growth</h2>  
                        <h5>               
                            You understand patience, but that doesn't mean you want to wait... and wait... and wait... for apparent future results.
                            <br/><br/>
                            All the while paying out month after month.
                            <br/><br/>
                            With our revenue focused approached, results are faster, higher ROI, and you'll never be locked into any long term contracts. Ever.
                        </h5>             
                    </div>   
                </div>

                <div className="second">
                <div className="a2">
                        <h2>You want to reduce your customer acquisition cost (CAC)</h2>  
                        <h5>           
                            Like it or not, you've got to pay to acquire customers.
                            <br/><br/>
                            That can be ads, platform fees, time spent on social media, content, etc.
                            <br/><br/>
                            Great SEO is no different. Except it's 6.67x cheaper than ads for our typical client. And it doesn't vanish when you stop paying.
                            <br/><br/>
                            The end result is usually more traffic, more revenue, lower CAC, and more long term.
                        </h5>             
                    </div>
                    <div className="b2">
                        <img src={mc} alt=""/>
                    </div>
                </div>
                
            


            </div> 
            <div className="header">
                <h3>Our results speak for themselves</h3>
            </div>

            <Results/>

            <div className="header">
                <h3>Trusted by the experts</h3>
            </div>

            <div className="experts">

                <div className="ex ex1">
                    <div className="logo"><img src={sej} alt=""/></div>
                    <div className="quote"> 
                    <h5>"Geek Squad Digital blog is result driven. Everything they write and and put out is field tested"</h5> 
                        <div className="quote-icon">
                            <img src={qt} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="ex ex2">
                    <div className="logo"><img src={ah} alt=""/></div>
                    <div className="quote"> 
                    <h5><strong>15th Best SEO Blog</strong> as ranked by Ahrefs
                        <br/><br/>
                        "Geek Squad Digital is relatively new to the SEO world, but 
                        is quickly becoming a household name."
                        </h5> 
                        <div className="quote-icon">
                            <img src={qt} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="ex ex3">
                    <div className="logo"><img src={th} alt=""/></div>
                    <div className="quote"> 
                    <h5><strong>Top SEO Podcast</strong> by The HOTH</h5> 
                        <div className="quote-icon">
                            <img src={qt} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="ex ex4">
                    <div className="logo"><img src={pop} alt=""/></div>
                    <div className="quote"> 
                    <h5><strong>13th Most Influencial SEO of 2020</strong> by PageOptimizer Pro</h5> 
                        <div className="quote-icon">
                            <img src={qt} alt=""/>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="header">
                <h3>Check out our YouTube channel</h3>
            </div>
            <div className="yt-stats">
                <div className="stat stat1">
                    <h4>12,000+</h4>
                    <h6>Subscribers</h6>
                </div>

                <div className="stat stat2">
                    <h4>700,000+</h4>
                    <h6>Views</h6>
                </div>

                <div className="stat stat3">
                    <h4>96+</h4>
                    <h6>Videos</h6>
                </div>
            </div>
            <div className="yt-img">
                <img src={yt} alt=""/>
            </div>
        </>
    )
}
export default About;