import React, {useEffect} from "react";
import { InlineWidget } from "react-calendly";

import './book.css'

import bag from './assets/icon-bag.svg';
import revenue from './assets/icon-revenue.svg';
import shopify from './assets/icon-shopify.svg';
import shopping from './assets/icon-shopping.svg'

const BookCall = () =>{

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    return (
        <>
            <div className="main">
                <div className="m1">
                    <h3>Schedule a call to see how we can grow your search revenue →</h3>

                    <h5>No sales poeple, no fluff, no hardsell</h5>

                    <h4>We can help you if...</h4>

                    <div className="m1a">
                        <div className="m1p">
                            <div className="m1i">
                                <img src={bag} alt=''/>
                            </div>
                            <div className="m1t">
                                <h4>You have a budget ready</h4>
                                <h5>If you are still researching you can check our free blogs, ebook, and course</h5>
                            </div>
                        </div>

                        <div className="m1p">
                            <div className="m1i">
                                <img src={shopify} alt=''/>
                            </div>
                            <div className="m1t">
                                <h4>You are an eCommerce store</h4>
                                <h5>All our processes and custom tools are build for eCommerce SEO</h5>
                            </div>
                        </div>                   

                        <div className="m1p">
                            <div className="m1i">
                                <img src={revenue} alt=''/>
                            </div>
                            <div className="m1t">
                                <h4>You're over 500k/yr revenue</h4>
                                <h5>New sites and brands take too long to deliver exceptional results</h5>
                            </div>
                        </div>

                        <div className="m1p">
                            <div className="m1i">
                                <img src={shopping} alt=''/>
                            </div>
                            <div className="m1t">
                                <h4>You have 50+ products</h4>
                                <h5>Driving SEO traffic to shopping pages require a lot of products</h5>
                            </div>
                        </div>
                    
                    </div>
                </div>
                <div className="m2">
                <InlineWidget url="https://calendly.com/samiwafula3257" />
                </div>
            </div>
        </>
    )
}

export default BookCall;