import React, {useEffect} from "react";
import './case.css';

import ResSlide from "./ResOne";

import x from './assets/icon-x.png';
import reb from './assets/reebelo-logo.svg';
import lg from './assets/ness.png';
import rebchart from './assets/eebelo-au-google-analytics-chart.webp';

import user from './assets/svg/user.svg';
import keyboard from './assets/svg/keyboard.svg';
import dollar from './assets/svg/dollar.svg';
import medal from './assets/svg/medal.svg';
import trophy from './assets/svg/trophy.svg';
import cash from './assets/svg/cash.svg';
import qm from './assets/svg/icon-question.svg';

import reb1 from './assets/reebelo-pp.webp';
import reb2 from './assets/reebelo-product-parameters.webp';
import rebvid from './assets/reebelo-product-parameters.gif';
import rebcol from './assets/reebelo-collection.webp';
import rebmulti from './assets/reebelo-multiple-h1s.webp';
import rebdesc from './assets/reebelo-descriptions.webp'

//reebelo

const CaseOne = () =>{

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    const handleScrollOne = () =>{
        const elm1 = document.getElementById('Sec-a');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollTwo = () =>{
        const elm1 = document.getElementById('Sec-b');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollThree = () =>{
        const elm1 = document.getElementById('Sec-c');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    return (
        <>
        <div className="body">
            <div className="first">
                <div className="menu">
                    <div className="men"><img src={lg} alt=""/></div>
                    <div className="men"><img src={x} alt=""/></div>
                    <div className="men"><img src={reb} alt=""/></div>
                </div>
                <h3>+$323,652 in Annual Traffic<br/> Value for Reebelo </h3>
                
            </div>

            <div className="cs-b">
                <div className="cs-a">
                    <h4>The Client 🔍</h4>
                    <h5>Reebelo is a fast growing marketplace for 
                        refurbished and pre-loved devices in multiple 
                        countries built on Shopify with a custom headless storefront.</h5>
                </div>

                <div className="cs-a">
                    <h4>The Objective 🏆</h4>
                    <h5>Establish Reebelo as the #1 marketplace for 
                        refurbished phones and devices with leading SEO rankings.</h5>
                </div>

                <div className="cs-a">
                    <h4>The Competition 🔥</h4>
                    <h5>Catch 😀<br/>
                        Kogan 😀<br/>
                        Phonebot 😀
                    </h5>
                </div>
            </div>

            <div className="cs-c">
                <div className="cs-c-txt">
                    <h4>Start Point</h4>
                    <h5>Reebelo had raised over $20m in venture capital 
                        and were investing heavily in branding and growth
                         which was performing great, but SEO still had 
                         some missed opportunities.</h5>
                </div>

                <div className="cs-c-img">
                    <img src={rebchart} alt="" />
                </div>
            </div>

            <div className="cs-c">
                <div className="cs-c-txt">
                    <h4>Results</h4>
                    <h5>Traffic skyrocketed from the top rankings across almost every device showing growth of over 5x users per month within 6 months.</h5>
                </div>

                <div className="cs-c-imge">
                    <div className="ic">
                        <img src={user} alt=""/>
                        <h3>+537.64%</h3>
                        <h4>Users</h4>
                    </div>

                    <div className="ic">
                        <img src={keyboard} alt=""/>
                        <h3>+767.85%</h3>
                        <h4>Transactions</h4>
                    </div>

                    <div className="ic">
                        <img src={dollar} alt=""/>
                        <h3>+823.52%</h3>
                        <h4>Revenue</h4>
                    </div>

                    <div className="ic">
                        <img src={cash} alt=""/>
                        <h3>+$323,652</h3>
                        <h4>Annual Traffic Value</h4>
                    </div>

                    <div className="ic">
                        <img src={trophy} alt=""/>
                        <h3>+183</h3>
                        <h4>Top 3 Keywords</h4>
                    </div>

                    <div className="ic">
                        <img src={medal} alt=""/>
                        <h3>+1,304</h3>
                        <h4>Page 1 Keywords</h4>
                    </div>

                </div>
            </div>

            <div className="cs-d">
                <img src={qm} alt=""/>
                <h3>How do you become a market leader in Google Search as a new<br/> player against decade old businesses?</h3>
                <div className="dots">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="cs-e">
                <div className="cs-e-a">
                    <h4>The Geek Squad Digital Playbook</h4>
                    <h5>No two projects are the same, but the high 
                        level strategy never changes. Here are the 3 
                        steps we use every time to multiply our clients 
                        organic revenue, along with the specific tactics 
                        we used for this client campaign.</h5>
                </div>

                <div className="cs-e-b">
                    <div className="e-b" onClick={handleScrollOne}>
                        <div className="num one">
                            <h5>1</h5>
                        </div>
                        <div className="b-txt">
                            <h5>Improve Site Quality</h5>
                        </div>
                    </div>

                    <div className="e-b"  onClick={handleScrollTwo}>
                        <div className="num two">
                            <h5>2</h5>
                        </div>
                        <div className="b-txt">
                            <h5>Expand Bottom of Funnel</h5>
                        </div>
                    </div>

                    <div className="e-b"  onClick={handleScrollThree}>
                        <div className="num three">
                            <h5>3</h5>
                        </div>
                        <div className="b-txt">
                            <h5>Optimise Bottom of Funnel</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cs-f">
                <h3 id="Sec-a">
                1. Improve Site Quality
                </h3>

                <h5>
                Technical site quality is usually the most overlooked part of eCommerce SEO, yet it’s insanely valuable for sites with 1,000+ pages.
                </h5>

                <h5>
                Reebelo had this beautiful headless commerce experience, amazing design, great UX - but had overlooked a number of SEO issues that came with it.
                </h5>

                <h5>
                Take a look at this product page for example:
                </h5>

                <img src={reb1} alt="" />

                <h5>Beautiful, right?</h5>

                <h5>But if you looked at the URL you’d see:</h5>

                <img src={reb2} alt="" />

                <h5>And if you clicked any of those options, these URL parameters changed each time.</h5>

                <img src={rebvid} alt="" />

                <h5>Every single option is a unique page. Do the maths, that’s over a thousand unique pages per product. That’s a crazy waste of crawl budget.</h5>

                <h5>So we recommended some options to fix this like blocking certain parameters from being crawled, or better yet, not passing them to the URL altogether.</h5>

                <h5>This was made even worse by the fact almost none of their pages had Canonical URLs.</h5>

                <h5>That’s a little tag that tells search engines where the original page is.</h5>

                <h5>So if you access the page via an ad, referral, or other URL parameters they don’t get indexed or treated as separate pages.</h5>

                <h5>But Reebelo didn’t have this, so those 100k+ parameter pages we referenced earlier? Yep, they were all indexable.</h5>

                <h5>Then there were just your standard bits and bobs.</h5>

                <h5>Open a collection page and all the product links contained the parameters like storage, color, and condition:</h5>

                <img src={rebcol} alt=""/>

                <h5>Again, not ideal when that’s not the URL we want to rank. Especially when not canonicalised.</h5>

                <h5>And every product had two H1 tags. The product name and the description heading.</h5>

                <img src={rebmulti} alt=""/>

                <h5>Overall though, the site was fantastic and extremely well set up. Unfortunately these few issues slipped through causing major issues, likely because developers are not SEOs and shouldn’t be expected to be.</h5>

                <h3 id="Sec-b">2. Expand Bottom of Funnel</h3>

                <h5>The next step is to expand the shopping pages. What other keywords are potential buyers searching to find your products?</h5>

                <h5>or Reebelo, they already had a page in place for every phone model and brand.</h5>

                <h5>But we noticed a trend very similar to what we’d experienced with Square Repair, 80% of the search volume was for iPhone and Samsung phones. With most of that being iPhones.</h5>

                <h5>So we thought about what buyers would be searching for.</h5>

                <h5>Refurbished iPhone [model], iPhone [model], pre-loved iPhone [model], pre-owned, etc.</h5>

                <h5>Of course, that was already in play.</h5>

                <h5>But what about colours? Or storage? Surely people searched for those too?</h5>

                <h5>The answer was a resounding yes, take a look at this for the iPhone 11:</h5>

                <table>
                    <tr>
                        <th>Keyword</th>
                        <th>Monthly Searches</th>
                    </tr>
                    <tr>
                        <td>iphone 11 purple</td>	
                        <td>600</td>
                    </tr>
                    <tr>
                        <td>iphone 11 green</td>
                        <td>600</td>
                    </tr>
                    <tr>
                        <td>iphone 11 white</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>iphone 11 red</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>iphone 11 black</td>
                        <td>350</td>
                    </tr>
                    <tr>
                        <td>iphone 11 yellow</td>
                        <td>250</td>
                    </tr>
                    <tr>
                        <td>iphone 11 128gb</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>iphone 11 256gb</td>
                        <td>90</td>
                    </tr>
                    <tr>
                        <td>iphone 11 64gb</td>
                        <td>60</td>
                    </tr>   
                </table>

                <h5>That’s an extra 2,950 searches per month to get in front of AND they’re all shopping intent.</h5>

                <h5>Best of all, remember all those parameters we covered before?</h5>

                <h5>Well, all those were technically separate pages, so the client already had all the pages in place. They just needed some technical SEO fixes and optimisation.</h5>

                <h5><i>This phase is still pending implementation at this point, we’ve shared all our data and strategies. Reebelo has their own talented in-house developers.</i></h5>

                <h3 id="Sec-c">3. Optimise Bottom of Funnel</h3>

                <h5>Finally, with the site quality up to scratch and the right pages in place, it’s time to improve all the existing and new pages.</h5>

                <h5>Take a look at any iPhone product on Reebelo now and you’ll see short sales focused descriptions:</h5>

                <img src={rebdesc} alt="" />

                <h5>These are nothing spammy or long, just to the point and optimised.</h5>

                <h5>Notice all the relevant keywords:</h5>

                <ul>
                    <li>Midnight, Purple, Starlight, Blue and Red</li>
                    <li>unlocked, new & refurbished iPhone 12 Pro Max</li>
                    <li>Australia</li>
                    <li>second hand iPhone 12 Pro Max</li>
                    <li>new iPhone 12 Pro Max</li>
                    <li>certified pre-owned iPhone 12 Pro Max</li>
                    <li>Buy</li>
                </ul>

                <h5>Descriptions don’t always need to be excessively long, especially when you’ve got a great brand. Short persuasive, optimised descriptions perform great in this case.</h5>

                <h5>Repeat this across every collection and product (in progress), you can see why Reebelo outrank everyone except Apple themselves for keywords like refurbished iphone, refurbished iphone 11, refurbished ipad, refurbished macbook, and refurbished iphone 8.</h5>

                <h3>Results</h3>

            </div>

            <ResSlide/>
        </div>

        </>
    )
}

export default CaseOne;