import React from "react";
import './foo.css';

import cart from '../homepage/assets/cart.webp';
import logi from '../cases/assets/ness.png'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
        <div className="seven">
                <div className="a7">
                    <h3>Our clients have seen an average<br/> 10.55x ROI.
                        What's the potential for<br/>  your store?
                    </h3>

                    <p>Book a free analysis and we'll crunch your data,
                         analyse competitors, and show you the revenue you should be seeing
                    </p>

                    <h5><Link to='/contact'>Book a Free Analysis</Link></h5>
                </div>
                <div className="b7">
                    <img src={cart} alt=""/>
                </div>
        </div>
        {/* <div className="f-b"></div> */}
        <div className="footer">
            <div className="f1">
                <img src={logi} alt=''/>
                <h5 className="men-a"><Link to="contact">Schedule a call</Link></h5>
                <h5><a href="tel:+1 984-480-5093" className="white-link">call: +1 984-480-5093</a></h5>
                <h5><a href="mailto:support@geeksquadagency.com" className="white-link">mail: support@geeksquadagency.com</a></h5>
            </div>
            <div className="f2">
                <h4>Company</h4>
                <h5><Link to="about" className="white-link">About</Link></h5>
                <h5><Link to="process" className="white-link">Process</Link></h5>
                <h5><Link to="results" className="white-link">Results</Link></h5>
                <h5 className="men-a"><Link to="contact">Get in touch</Link></h5>
            </div>
        </div>
        </>
    )
}

export default Footer;