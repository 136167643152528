import React from "react";

import step4 from './assets/step-4.svg'

const StepFour = () => {
    return(
        <>
        <div className="S1">
            <img src={step4} alt=""/>
            <h5>step four</h5>
            <h4>Link Building</h4>
            <h6>Link building is the fuel behind the first 3 steps, when done correctly 
                it'll multiply the impact of this work.
                <br/><br/>
                We handle our link building 100% in-house by reaching out to niche 
                relevant websites on your behalf, it's simple and very effective.
            </h6>
        </div>
        </>
    )
}

export default StepFour;