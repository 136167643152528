import React, {useEffect, useNavigate} from "react";
import "./process.css"

import pr from './assets/process-image.png';
import tnc from './assets/traffic-not-converting.webp';
import rp from './assets/revenue-potential.webp';

import nav from './assets/nav.svg';
import res from './assets/res.svg';
import search from './assets/search.svg';
import gaps from './assets/gaps.svg';

import blinds from './assets/blindstyle.webp';
import soap from './assets/soapery-before-after.webp';
import deep from './assets/deep-dive-audit.jpg';
import cgaps from './assets/content-gaps.jpg'
import { Link, Outlet, Routes, Route } from "react-router-dom";

import Retailer from "./processComponents/Retailer";
import Consume from "./processComponents/DirectToConsumer";

const Process = ()=>{

    useEffect(() =>{
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        document.title = 'Geek Squad Digital | Process';
      }, []);

    const handleScrollOne = () =>{
        const elm1 = document.getElementById('Sec-1');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollTwo = () =>{
        const elm1 = document.getElementById('Sec-2');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollThree = () =>{
        const elm1 = document.getElementById('Sec-3');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollFour = () =>{
        const elm1 = document.getElementById('Sec-4');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    return (
        <>
        <div className="proc">
        <div className="p1">
            <div className="p1a">
                <h3>Search Marketing<br/> Should Grow Your <br/>Revenue, Not Traffic</h3>
                <h5 className="button"><Link to="/contact">Book a call</Link></h5>
            </div>
            <div className="p1b">
                <img src={pr} alt='' />
            </div>
        </div>

        <div className="p2">

            <h4>Why the marketing industry is wrong about e-commerce SEO and how our 
                revenue-first approach results in 143.28% 💰 growth on average.
            </h4>

            <h6>Most ecommerce companies spend months dialling in their PPC targeting, bidding,
                 and strategy. And then run blindly into SEO based on an automated audit:
                <br/>
                <ul>
                    <li><i>We have 432 pages with missing meta descriptions to fix</i></li>
                    <li><i>There's 1,988 images missing alt text to write</i></li>
                    <li><i>Our health score is only 70, we need to get it to 90+</i></li>
                </ul>
                <br/>           
                You then spend 6 months fixing all these issues. Finally achieving that perfect 100 quality score.
                <br/>   
                Only to realise it's had zero impact on your revenue.
                <br/> 
                It's called "Best Practice SEO".
                <br/> 
                And the problem is it's not a strategy. It's fixing so-called issues, at random, with no objective.
            </h6>

            <h5>
                What you need is a strategy for determining what to do, in which order, to grow revenue.
            </h5>

            <h6>
                Notice we didn't say traffic?
                <br/>
                Because traffic means hiring an army of cheap offshore writer to churn out mediocre blog content like it's going out of fashion, and then realising...
                <br/>
                None of this converts to sales.
            </h6>

            <img src={rp} alt=""/>

            <h6>
                Revenue-focused SEO, on the other hand, means prioritising your strategy based on the revenue potential of each input.
                <br/>
                <ul>
                    <li>What's the traffic value potential in our market (Total addressable market - TAM)?</li>
                    <li>What's the traffic value potential for this page?</li>
                    <li>How impactful will this change be on our revenue?</li>
                </ul>    
            </h6>

            <img src={tnc} alt=""/>

            <h6>
                When you make this shift in thinking, you come to a very simple conclusion:
                <br/>
                The only thing that really matters is product pages, collection pages, and your homepage. AKA your bottom of funnel.
                <br/>
                If you had just ONE major product or collection ranking first in Google Search, you'd probably multiply your search revenue. Now what if you had the top 50?
                <br/>
                This is what we do for our clients; not Best Practice SEO, nor content marketing, only revenue-focused SEO. We call it...
            </h6>   
        </div>

        <div className="head">
            <h3>
                The Geek Squad Digital Playbook 📓
            </h3>
        </div>
            <div className="strat1">
                <div>
                    <h5>step one</h5>
                    <h4>Store Strategy</h4>
                    <h6>
                    We pull apart your site from a structure and SEO perspective,<br/>
                    rethinking the entire organic traffic funnel and creating a <br/>
                    data-driven hit list of shopping pages to create and optimize.
                    </h6>
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        <div className="strategy">
            

            <div className="strat2">
                <div className="strat-nest-a">
                    <ul>
                        <li>
                            <div className="nest-a" onClick={handleScrollOne}>
                                <div className="nest-a-icon">
                                    <img src={nav} alt="" />
                                </div>
                                <div className="nest-a-text">
                                    <h5>Improved navigation</h5>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="nest-a" onClick={handleScrollTwo}>
                                <div className="nest-a-icon">
                                    <img src={res} alt="" />
                                </div>
                                <div className="nest-a-text">
                                    <h5>Page restructures</h5>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="nest-a" onClick={handleScrollThree}>
                                <div className="nest-a-icon">
                                    <img src={search} alt="" />
                                </div>
                                <div className="nest-a-text">
                                    <h5>Deep-dive audit</h5>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="nest-a" onClick={handleScrollFour}>
                                <div className="nest-a-icon">
                                    <img src={gaps} alt="" />
                                </div>
                                <div className="nest-a-text">
                                    <h5>Collection gaps</h5>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="strat-nest-b">
                    <h5 id="Sec-1">
                        1. Optimise your <span className="accent-p1">navigation</span> to drive traffic & sales
                    </h5>

                    <h6>
                        SEO, conversion, and user experience (UX) are not separate, they're highly interconnected and should be treated as such.
                        <br/>
                        How many clicks does it take from the homepage to get into a product page?
                        <br/>
                        Other than existing collections, how do your customers navigate and search for products? By Size? Colour? Type? Material?
                        <br/>
                        This changes your entire shopping flow, making it easier for your customers to find, filter, and buy products. That means more sales and revenue.
                        <br/>
                        Take "floor tiles", as an example, in the UK it has a traffic value of $288,000/yr for the top ranking site. But add up the top 30 keywords in this category like "kitchen floor tiles", "bathroom floor tiles", "vinyl floor tiles", etc. Now the total traffic value is $5,738,940/yr.
                        <br/>
                        That's a 20x bigger total addressable market (TAM) by improving your site structure and navigation.
                    </h6>

                    <img src={blinds} alt=""/>

                    <h5 id="Sec-2">
                        2. Restructure your pages for <span className="accent-p2">UX</span>
                    </h5>
                    <h6>                  
                        How can you add content to your collections or products without negatively impacting conversions?
                        <br/>
                        How do you navigate to subcategories without clogging up your main menu with endless links?
                        <br/>
                        How does your collections showcase your products compared to competitors? Is there additional info to display? More products pre-pagination?
                        <br/>
                        These are all questions that impact both the user shopping experience, but also your search traffic.
                        <br/>
                        We'll plan all this out based on your specific site and audience, then design Figma mockups to show our ideas ready for your developer
                    </h6>

                    <img src={soap} alt=""/>
                    
                    <h5 id="Sec-3">
                        3. Conduct a <span className="accent-p3">deep-dive audit</span> to identify traffic opportunities with existing pages
                    </h5>
                    <h6>
                        Your website has a treasure trove of SEO data hiding in Google Search Console, Google Analytics, your sitemap, and third party tools like Ahrefs, SEMRush, and Screaming Frog.
                        <br/>
                        When this is combined and analysed manually on a page-by-page basis, you're able to understand the traffic/revenue, traffic source, opportunities, issues, and growth steps for every single page.
                        <br/>
                        This is then clustered and prioritised as action items to implement.
                        <br/>
                        You'll know exactly which pages to work on, what to do for each page, and the traffic value potential for each of them.
                    </h6>

                    <img src={deep} alt=""/>
                    
                    <h5 id="Sec-4">
                        4. Create a traffic value prioritised list of <span className="accent-p4">new collections</span> to create
                    </h5>
                    <h6>
                        During our auditing, site restructuring, and research we're likely to identify 30-50+ new collection opportunities.
                        <br/>
                        These are new collections we can create to better filter and navigate through existing products e.g. sell "protein powders", add a "chocolate protein powders" collection.
                        <br/>
                        From this list, we'll deep-dive into each page idea to compile the traffic value, competition levels, and overall opportunity to prioritise creation of these collections.
                        <br/>
                        At this stage, you'll probably find out you're missing out on hundreds of thousands of dollars a year (if not millions) in traffic potential.
                    </h6>

                    <img src={cgaps} alt=""/>
                </div>
            </div>

        </div>

        <div className="strategy">
            <div className="strat1">
                <div>
                    <h5>step two</h5>
                    <h4>Shopping Page Optimization</h4>
                    <h6>
                    Based on the store strategy, you'll have a high converting and well structured site primed for great SEO performance.
                    <br/>
                    This initial strategy alone is likely to both increase traffic and revenue, next it's time to take this list of new & existing pages prioritised by traffic value, then capture that value.
                    <br/>
                    Here's how it may look for two common types of stores:
                    </h6>
                    <div className="roles">
                        <Link to="retailer" className="white-link"><div className="role-a">
                            Retailer
                        </div></Link>
                        <Link to="consumer" className="white-link"><div className="role-b">
                            Direct-to-consumer
                        </div></Link>
                    </div>
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
            {/* <Routes>
                <Route path="/" element={<Retailer/>}/>
                <Route path="consumer" element={<Consume/>}/>
            </Routes>
             */}
        </div>
        <div>
        <Routes>
            <Route path="/*" element={<Retailer/>}/>
            <Route path="/consumer" element={<Consume/>}/>
        </Routes>
        </div>

        <div className="strategy">
            <div className="strat1">
                <div>
                    <h5>step three</h5>
                    <h4>Add more pages/content/links</h4>
                    <h6>
                    Then we simply repeat this process based on the original plan:
                    <br/>
                    More shopping pages or sales content that wasn't created or improved yet? Order page plans.
                    <br/>
                    Need more backlinks? Submit an order.
                    <br/>
                    No retainers, contracts, or paying us while you wait for rankings.
                    </h6>
                </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default Process;