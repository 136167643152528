import React from "react";

import cart from './assets/cart.svg';
import col from './assets/cols.svg';
import env from './assets/envelope.svg';
import link from './assets/link.svg';

import sofa from '../assets/sofa-collections.png'
import topfunnel from '../assets/TopOfFunnel.png'

const Retailer = ()=>{

    const handleScrollOne = () =>{
        const elm1 = document.getElementById('Sec-aa');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollTwo = () =>{
        const elm1 = document.getElementById('Sec-bb');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollThree = () =>{
        const elm1 = document.getElementById('Sec-cc');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    const handleScrollFour = () =>{
        const elm1 = document.getElementById('Sec-dd');
        if (elm1) {
            elm1.scrollIntoView({ behavior: 'smooth' })
        }
    };

    return (
    <>
        <div className="strat2">
            <div className="strat-nest-a">
                <ul>
                    <li>
                        <div className="nest-a" onClick={handleScrollOne}>
                            <div className="nest-a-icon">
                                <img src={cart} alt="" />
                            </div>
                            <div className="nest-a-text">
                                <h5>Optimise shopping pages</h5>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="nest-a" onClick={handleScrollTwo}>
                            <div className="nest-a-icon">
                                <img src={col} alt="" />
                            </div>
                            <div className="nest-a-text">
                                <h5>Create new collections</h5>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="nest-a" onClick={handleScrollThree}>
                            <div className="nest-a-icon">
                                <img src={env} alt="" />
                            </div>
                            <div className="nest-a-text">
                                <h5>Add sales content</h5>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="nest-a" onClick={handleScrollFour}>
                            <div className="nest-a-icon">
                                <img src={link} alt="" />
                            </div>
                            <div className="nest-a-text">
                                <h5>Link building</h5>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="strat-nest-b">
                <h5 id="Sec-aa">
                    1. Optimise your <span className="c1-accent">shopping pages</span>
                </h5>

                <h6>
                
                    You likely have thousands of products and hundreds of collections to work on.
                    <br/><br/>
                    These pages often convert 2-3x more sales than blog content as it's literally people searching to buy your products. We call this bottom of funnel, it's priority one.
                    <br/><br/>
                    We'll rank these pages based on opportunity, then make manual SEO recommendations for each individual page looking at factors such as:
                    <br/><br/>
                    <ul>
                        <li>Title tag / Meta changes</li>
                        <li>Internal linking opportunities</li>
                        <li>Content expansion opportunities (on this page vs on site)</li>
                        <li>Missing subheadings, phrases, etc for keyword and topical relevance</li>
                        <li>Competitor comparison</li>
                    </ul>
                    <br/><br/>
                    Each of these changes will be based on the value of this page. One single product or collection with the right intent, could drive upwards of $100,000+/yr in revenue if it ranked well in search.
                </h6>

                <h5 id="Sec-bb">
                    2. Create new <span>collections</span> to increase your TAM
                </h5>
                <h6>                                 
                    A sofa (232k/mo UK searches) isn't simply a sofa. It's also a:
                    <br/><br/>
                    <ul>
                        <li>corner sofa (144k/mo)</li>
                        <li>corner sofa bed (48k/mo)</li>
                        <li>chesterfield sofa (37k/mo)</li>
                        <li>leather sofa (30k/mo)</li>
                        <li>grey corner sofa (20k/mo)</li>
                        <li>3 seater sofa (19k/mo)</li>
                        <li>grey sofa (17k/mo)</li>
                    </ul>
                    <br/><br/>
                    Or put another way, there's 7.7m monthly searches containing the word sofa. That's 33x bigger than the single keyword.
                    <br/><br/>
                    But you can't rank for these pages unless you have a collection in place targeting them.
                    <br/><br/>
                    Our analysis often finds Shopify stores missing out on $100,000+/yr in traffic value by simply not creating enough collections.
                    <br/><br/>
                    So we'll analyse this and make all the recommendations for you: what collections to create, how to optimise them, what content to add, etc.


                </h6>

                <img src={sofa} alt=""/>
                
                <h5 id="Sec-cc">
                    3. Drive more revenue with <span className="c3-accent">sales assisting content</span>
                </h5>
                <h6>
                
                    The final stage of content that's specifically created to assist with driving sales. No fluff pieces, no listicles, only sales-focused content.
                    <br/><br/>
                    The goal is to convert more sales and position your site as a topical authority.
                    <br/><br/>
                    Let's take one of our clients that sells brick slips, beyond the bottom of funnel searches such as:
                    <br/><br/>
                    <ul>
                        <li>brick slips (25k monthly searches)</li>
                        <li>brick slips uk (2.3k monthly searches)</li>
                        <li>brick slips near me (1.8k monthly searches)</li>
                    </ul>

                    <br/><br/>
                    There's people also interested in:
                    <br/><br/>
                    <ul>
                        <li>how many brick slips</li>
                        <li>how to fit brick slips (250 monthly searches)</li>
                        <li>how to cut brick slips (80 monthly searches)</li>
                    </ul>
                    <br/><br/>
                    By covering these topics with content, you're assisting your sales process, reducing customer service, and expanding the topical authority of your site (which helps rankings for all keywords in this topic).
                </h6>

                <img src={topfunnel} alt=""/>
                
                <h5 id="Sec-dd">
                    4. Rank keywords through <span className="c4-accent">link building</span>
                </h5>
                <h6>
                
                    Based on your DTS audit, you may need more backlinks to rank for certain keywords.
                    <br/><br/>
                    Using our strategy plan of which pages to point links to, how many links to build, what anchor text to use, etc. We'll do the outreach and find link opportunities for you.
                    <br/><br/>
                    This involves:
                    <br/><br/>
                    <ul>
                        <li>Creating a list of relevant websites/articles that would be beneficial to get a link from</li>
                        <li>Vet the quality of the websites to check they're of decent quality and how impactful a link would be</li>
                        <li>Reach out to these websites by email to see if they're open to this</li>
                        <li>Discuss and negotiate link placement details</li>
                        <li>Write the content for the link placement (if needed)</li>
                        <li>Monitor the link status and update you once live</li>
                    </ul>

                </h6>
            </div>
        </div>
    </>
    )
}

export default Retailer;